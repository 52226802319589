<template>
  <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
    <div class="accordion" :id="id">
      <div
        class="accordion-item card"
        v-for="(content, index) in accordionContent"
      >
        <h2 class="accordion-header card-header" :id="`heading${content.id}`">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            :data-bs-target="`#collapse${content.id}`"
            :aria-expanded="index === activeCollapse ? 'true' : 'false'"
            :aria-controls="`collapse${content.id}`"
            @click="activeCollapse = index"
          >
            {{ content.title }}
            <i class="collapse-icon" />
          </button>
        </h2>
        <div
          :id="`collapse${content.id}`"
          class="accordion-collapse collapse"
          :class="{ show: index === 0 }"
          :aria-labelledby="`heading${content.id}`"
          :data-bs-parent="`#${id}`"
        >
          <div
            class="accordion-body card-body"
            v-html="content.description"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    id: {
      type: String,
      default: "accordionExample",
    },
    accordionStyleClass: {
      type: String,
    },
    accordionContent: {
      type: Array,
      default: function() {
        return [
          {
            id: "One",
            title: "Dress Code & Code of Conduct",
            description: `<ul>
                <li>When you arrive, ensure you take a picture of the storefront. <strong>Text me the picture when you arrive</strong> and add it to the recap form after the event.</li>
                <li>Black shirt and pants or black dress</li>
                <li>Makeup and hair done</li>
                <li>No jackets, sneakers or other accessories that may seem inappropriate</li>
                <li>No Phone use during the event unless you are taking a picture related to the event</li>
                <li>Be on-time and make sure to stay for the full event</li>
                <li>Make sure to submit the <a href="/recap">recap</a> form within 24 hours of the event completion</li>
                <li>The recap requires specific information, therefore please review it and familiarize yourself before the event to ensure you collect all the required information</li>
                <li>Keep track of sales and submit it with the recap</li>
                <li>Submit any expenses and receipts with the recap</li>
                </ul>`,
          },
          {
            id: "Two",
            title: "Setup",
            description: `<ul>
                <li>A table should be setup with ice bucket and only the bottles that are part of the event</li>
                <li>Make sure to have a picture taken of you with the table setup for the recap</li>
                <li>Make sure to bring ice, ice bucket and cups and expense it via the recap form</li>
                </ul>`,
          },
          {
            id: "Two",
            title: "Product Not in Stock",
            description:
              "If you are asked to purchase the product at the venue of the event and they do not have it, please contact Alina before making any changes.",
          },
          {
            id: "Three",
            title: "How soon does the recap need to be submitted?",
            description:
              "The recap <strong>must</strong> be submitted within 24 hours of the event.",
          },
          {
            id: "Four",
            title: "Running late to the event",
            description:
              "You must report any lateness asap to Alina who can call the venue and coordinate appropriatelly.",
          },
        ];
      },
    },
  },
  data() {
    return {
      activeCollapse: 0,
    };
  },
};
</script>
