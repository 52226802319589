<template>
  <Layout :footer-style="2" :showFooter="false">
    <!-- Start Contact Area  -->
    <div class="rwt-contact-area">
      <iframe
        frameborder="0"
        style="height:1000px;width:99%;border:none;"
        src="https://forms.zohopublic.com/cieloagencyllc/form/CieloAgencyRecap/formperma/Sb71K6JWpw2w2sP4fQm36fbekG5mKqHGk055c0bn2Ns"
      ></iframe>
    </div>
    <!-- End Contact Area  -->
  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Breadcrumb from "../components/elements/breadcrumb/Breadcrumb";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import Icon from "../components/icon/Icon";

export default {
  name: "RecapPage",
  components: { Icon, SectionTitle, Breadcrumb, Layout },
};
</script>
