import Vue from "vue";
import VueRouter from "vue-router";
import Jobs from "../views/Jobs";
import ContactPage from "../views/ContactPage";
import TermsPage from "../views/Terms";
import PrivacyPage from "../views/Privacy";
import MarketAgency from "../views/home-pages/MarketAgency";
import Recap from "../views/Recap";
import FAQPage from "../views/FAQPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: MarketAgency,
    meta: {
      title: "Cielo Agency || Marketing beverage brands for over 15 years",
    },
  },
  {
    path: "/jobs",
    name: "Careers",
    component: Jobs,
    meta: {
      title: "Careers for Brand Ambassadors || Cielo Agency",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: ContactPage,
    meta: {
      title: "Contact || Cielo Agency",
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQPage,
    meta: {
      title: "FAQ || Cielo Agency",
    },
  },
  {
    path: "/recap",
    name: "Recap",
    component: Recap,
    meta: {
      title: "Submit Event Recap || Cielo Agency",
    },
  },
  {
    path: "/terms",
    name: "Terms",
    component: TermsPage,
    meta: {
      title: "Terms of Use || Cielo Agency",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: PrivacyPage,
    meta: {
      title: "Privacy Policy || Cielo Agency",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0);
});

export default router;
