<template>
    <ul class="mainmenu">
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/jobs">Careers</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
    </ul>
</template>

<script>
    import Icon from "../../icon/Icon";
    export default {
        name: 'Nav',
        components: {Icon},
        methods: {
            scrollToAnchor(refName) {
                console.log('got here')
                var element = this.$refs[refName]
                element.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }
</script>