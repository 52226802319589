<template>
  <Layout
    header-class="header-transparent"
    :show-newsletter="true"
    :show-cta="false"
    :footer-style="3"
  >
    <!-- Start Slider Area -->
    <div
      class="slider-area slider-style-1 height-850 bg_image"
      data-black-overlay="7"
      :style="{
        'background-image': `url(${require(`@/assets/images/bg/marketing-career-oportunities.jpg`)})`,
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="inner pt--80 text-center">
              <div>
                <h3
                  class="rn-sub-badge"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <span class="theme-gradient"
                    >Contact us for job opportunities</span
                  >
                </h3>
              </div>
              <h1
                class="title display-one"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                We are always looking for <br />
                Brand Ambassadors!
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Slider Area -->

    <!-- Start Service Area -->
    <div class="rn-service-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
              text-align="center"
              title="Responsibilities."
              description="Here are some of the responsibilities for a brand ambassador position."
              data-aos="fade-up"
            />
          </div>
        </div>
        <ServiceOne
          service-style="service__style--1 bg-color-blackest radius mt--25"
          icon-size="62"
          text-align="center"
        />
      </div>
    </div>
    <br />
    <Separator />

    <!-- End Service Area -->
    <br />
    <div class="rwt-timeline-area rn-section-gapBottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <Timeline :timeline-data="timelineData" :timeline-style="3" />
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>

<script>
import Layout from "../components/common/Layout";
import Separator from "../components/elements/separator/Separator";
import SectionTitle from "../components/elements/sectionTitle/SectionTitle";
import ServiceOne from "../components/elements/service/ServiceOne";
import Timeline from "../components/elements/timeline/Timeline";

export default {
  name: "About",
  components: {
    Timeline,
    ServiceOne,
    SectionTitle,
    Separator,
    Layout,
  },
  data() {
    return {
      timelineData: [
        {
          id: "1",
          title: "flexibility",
          description:
            "Flexible scheduling; sign up for events based on your own availability and ability to travel.",
        },
        {
          id: "2",
          title: "hours",
          description:
            "Events typically range from 3-4 hours allowing you still maintain a regular 9-5 job.",
        },
        {
          id: "3",
          title: "pay",
          description: "Competitive pay (1099) - paid weekly.",
        },
        {
          id: "4",
          title: "location",
          description:
            "We need brand ambassadors everywhere in Long Island, NY. You can pick the ones that you are willing to travel to.",
        },
      ]
    };
  },
};
</script>
