<template>
    <Layout :topbar-style="2" :footer-style="2">
        <!-- Start Slider Area -->
        <div class="slider-area slider-style-1 bg-transparent variation-2 height-750">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                        <div class="inner text-left">
                            <h1 class="title theme-gradient display-two" data-aos="fade-up" data-aos-delay="100">
                                Marketing Agency <br/>For
                                <vue-typed-js class="d-inline-block"
                                              :strings="['Beverages.', 'Consumers.', 'Producers.']"
                                              :loop="true"
                                              :type-speed="80"
                                              :start-delay="1000"
                                              :back-speed="50">
                                    <span class="typing"></span>
                                </vue-typed-js>
                            </h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="150">
                                We help our clients succeed by promoting their brand identity through successful programs specifically crafted to their needs. Serving Long Island, NY region for over 15 years.
                            </p>
                            <div class="button-group" data-aos="fade-up" data-aos-delay="200">
                                <router-link class="btn-default btn-medium btn-border round btn-icon" to="/contact">
                                    Contact Us
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 order-1 order-lg-2">
                        <div class="thumbnail" data-aos="fade-up" data-aos-delay="250">
                            <img src="../../assets/images/cielo-agency-tasting-brand-marketing.jpg" alt="Cielo Agency Brand Marketing"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Slider Area -->

        <Separator/>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap" id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="What we can do for you"
                            title="Our Services"
                            description=""
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceFour
                    service-style="service__style--1 icon-circle-style with-working-process"
                    text-align="center"
                />
            </div>
        </div>
        <!-- End Service Area -->

        <Separator/>

        <!-- Start Elements Area -->
        <div class="rwt-tab-area rn-section-gap">
            <div class="container">
                <div class="row mb--40">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="how we do it"
                            title="Acquire new customers with Cielo Agency"
                            description="Our team of brand ambassadors will help you drive product sales <br /> and promote brand awareness."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Tab :tab-data="tabData"/>
            </div>
        </div>
        <!-- End Elements Area -->

        <Separator/>

    </Layout>
</template>

<script>
    import Layout from "../../components/common/Layout";
    import Separator from "../../components/elements/separator/Separator";
    import SectionTitle from "../../components/elements/sectionTitle/SectionTitle";
    import Icon from "../../components/icon/Icon";
    import ServiceFour from "../../components/elements/service/ServiceFour";
    import AdvancedTab from "../../components/elements/tab/AdvancedTab";
    import Tab from '../../components/elements/tab/Tab'
import ServiceThree from "../../components/elements/service/ServiceThree.vue";

    export default {
        name: 'MarketAgency',
        components: { AdvancedTab, ServiceFour, Icon, SectionTitle, Separator, Layout, Tab },
        data() {
            return {
                tabData: {
                    tabImage: 'cielo-agency-marketing.jpg',
                    tabContent: [
                        {
                            id: 1,
                            menu: 'strategy',
                            content: `<p>We work for diffferent brands through a direct relationship or through other sales and marketing agencies. The end goal is the same, which is to promote the brand and products to consumers.</p><p>Our team of brand amabssadors can be tailored to specific requirements such as beverage preparation, setup of the sampling station, sales tracking and of course educating consumers about the brand and the products.<br /><br /></p>`,
                        },
                        {
                            id: 2,
                            menu: 'reporting',
                            content: `<p>We will work with you to customize the reporting to ensure you have the visibility on how your brand is doing.</p><p>Information such as sales statistics, customers sampled, customer feedback, demographic data, pictures of the event and much more can be requested as part of the event reporting.<br /><br /><br /></p>`,
                        },
                        {
                            id: 3,
                            menu: 'location',
                            content: `<p>At this time, we service Nassau and Suffolk counties in Long Island, NY with plans for expansion to the tri-state area in the very near future.</p><p><br /><br /><br /><br /><br /></p>`,
                        }
                    ]
                }
            }
        }
    }
</script>