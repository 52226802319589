<template>
  <div>
    <div v-if="sent" class="alert" :class="className">
      <div class="message-header">
        <p>{{ sent }}</p>
      </div>
      <div class="message-body">
        {{ statusBody }}
      </div>
    </div>
    <form
      v-if="!isHidden"
      name="ask-question"
      class="contact-form-1"
      netlify
      netlify-honeypot="bot"
      data-netlify-recaptcha="true"
      method="post"
      @submit.prevent="handleSubmit"
    >
      <input type="hidden" name="form-name" value="ask-question" />
      <p class="is-hidden">
        <label>
          Don’t fill this out if you’re human:
          <input v-model="form.bot" name="bot" />
        </label>
      </p>
      <div
        v-if="errors.length"
        class="row row--15 service-wrapper"
        @click="clearErrors"
      >
        <div class="alert alert-warning" data-aos="fade-up" data-aos-delay="70">
          <ul>
            <li v-for="(error, index) in errors" :key="index">
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
      <div class="form-group">
        <input
          v-model="form.name"
          type="text"
          name="name"
          placeholder="Your Name"
          required
        />
      </div>

      <div class="form-group">
        <input
          v-model="form.email"
          type="email"
          name="email"
          placeholder="Email Address"
          required
        />
      </div>

      <div class="form-group">
        <select
          v-model="form.category"
          name="category"
          required
        >
          <option value="---Choose Category---">---Choose Category---</option>
          <option value="Career Oportunities">Career Oportunities</option>
          <option value="Become a Client">Become a Client</option>
          <option value="General">General</option>
        </select>
      </div>

      <div class="form-group">
        <input
          v-model="form.subject"
          type="text"
          name="subject"
          placeholder="Subject"
          required
        />
      </div>

      <div class="form-group">
        <textarea
          v-model="form.details"
          name="details"
          placeholder="Your Message"
          required
        >
        </textarea>
      </div>
      <div class="field">
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="captchaKey"
          @verify="onVerify"
        ></vue-recaptcha>
      </div>
      <br />
      <div class="form-group">
        <button class="btn-default btn-large" type="submit">Submit Now</button>
      </div>
    </form>
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "QuestionForm",
  data() {
    return {
      form: {
        name: "",
        email: "",
        subject: "",
        category: "---Choose Category---",
        details: "",
        bot: "",
      },
      sent: "",
      statusBody: "",
      className: "",
      isHidden: false,
      errors: [],
      robot: true
    };
  },
  computed: {
    captchaKey() {
      return process.env.VUE_APP_SITE_RECAPTCHA_KEY;
    }
  },
  components: {
    VueRecaptcha,
  },
  methods: {
    onVerify: function(response) {
      if (response) this.robot = false;
    },
    clearErrors() {
      this.errors = [];
    },
    validateForm() {
      this.errors = [];
      if (!this.form.name) {
        this.errors.push("Name is required.");
      }
      if (!this.form.subject) {
        this.errors.push("Subject is required.");
      }
      if (this.form.category === '---Choose Category---') {
        this.errors.push("Category is required.");
      }
      if (!this.form.details) {
        this.errors.push("Message is required.");
      }
      if (!this.form.email) {
        this.errors.push("Email is required.");
      } else if (!this.validEmail(this.form.email)) {
        this.errors.push("Valid email is required.");
      }
      if (!this.errors.length) {
        return true;
      } else {
        return false;
      }
    },
    validEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    encode(data) {
      let result = Object.keys(data)
        .map(
          (key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
      if (document.getElementById("g-recaptcha-response")) {
        const captchaValue = document.getElementById("g-recaptcha-response")
          .value;
        const captcha = `${encodeURIComponent(
          "g-recaptcha-response"
        )}=${encodeURIComponent(captchaValue)}`;
        result = result + "&" + captcha;
      }
      return result;
    },
    async handleSubmit(e) {
      let token = null;

      if (this.validateForm()) {
        console.log("here");
        if (!this.robot) {
          await fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: this.encode({
              "form-name": "ask-question",
              ...this.form,
            }),
          })
            .then((response) => {
              if (response.ok) {
                this.sent = "Success";
                this.statusBody = "Thank you! Your request has been submitted.";
                this.className = "alert-success";
                this.isHidden = true;
              } else {
                this.sent = "Failed";
                this.statusBody =
                  "Sorry but your request could not be submitted";
                this.className = "alert-warning";
              }
            })
            .catch((error) => {
              this.sent = "Failed";
              this.statusBody = error;
              this.className = "alert-warning";
            });
        } else {
          this.errors.push("Confirm you are not a robot.");
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.is-hidden {
  display: none !important;
}
select {
  border-color: #00000024;
  border-radius: var(--radius);
  height: 70px;
  font-size: 16px;
  @extend %transition;
  padding: 0 15px;
  color: gray;
}
</style>
